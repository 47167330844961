.admin {
  padding-top: 1em;
  padding-bottom: 1em;
  min-height: 500px;
}

.admin__item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.admin__title {
  color: #009499;
  font-size: 20px;
  text-align: center;
  height: 55px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.admin__img-wrapper {
  position: relative;
}

.admin__img {
  max-height: 250px;
  height: 250px;
  width: 100%;
}

.fa-check-square {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
  color: #009499;
}

.fa-eye {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 30px;
  color: #009499;
}

.admin__buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.admin__offers {
  min-height: 300px;
}

.admin-panel__no-offer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #009499;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
}

.fa-vihara {
  font-size: 50px;
  margin-top: 20px;
}

.textarea--admin {
  height: 300px;
}
