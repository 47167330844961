@import '~react-image-gallery/styles/scss/image-gallery.scss';
$font-grey: #4d4d4d;
$brand-main: #009499;
$brand-main-light: #0aafb4;
$brand-second: #f7e4a2;

.offer-top__text-price {
  font-size: 24px;
  color: $font-grey;
  font-weight: 700;
}

.offer-top__text-accent {
  font-size: 24px;
  color: $font-grey;
  font-weight: 600;
}

.offer-top__text-location {
  font-size: 16px;
  color: $font-grey;
}

.offer-top__content {
  margin-top: 10px;
}

.offer-top__text-location-wrapper {
  margin-top: 10px;
}

.offer-description-tablet {
  display: none;
}
.offer-description__title,
.offer-map__title,
.offer-form__title {
  margin-bottom: 20px;
}

.offer-description__text {
  font-size: 16px;
  margin-bottom: 35px;
  color: $font-grey;
}

.offer-description__text:last-child {
  margin-bottom: 0px;
}

.info-item {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $font-grey;
}

.info-item:first-child {
  border-top: 1px solid $font-grey;
}

.info-item__key {
  font-weight: 400;
  font-size: 16px;
  color: $font-grey;
  text-align: left;
}

.info-item__value {
  font-weight: 600;
  font-size: 16px;
  color: $font-grey;
  text-align: right;
}

.offer-map__map {
  iframe {
    width: 100%;
    height: 400px;
  }
}
.offer-form {
  form {
    padding: 20px;
    border: 1px solid $font-grey;
    margin-bottom: 2em;
  }
}

.button--form {
  width: 250px;
  border-radius: 22px;
  display: block;
  margin: 0 auto;
  height: 55px;
}

input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $font-grey !important;
  opacity: 1; /* Firefox */
  font-family: 'Open Sans';
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $font-grey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $font-grey;
}

.offer-form__error {
  color: red;
  padding: 2px 5px;
}

.offer-form__message {
  max-width: 300px;
  text-align: center;
  margin: 1em auto;
  padding: 5px;
}

.message--succeed {
  box-shadow: 0px 1px 15px 1px rgba(0, 148, 153, 0.6);
}

.message--failed {
  box-shadow: 0px 1px 15px 1px rgba(214, 0, 0, 0.6);
}

.banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.banner__img {
  max-width: 200px;
}

.fa-phone-square-alt,
.fa-phone-alt {
  font-size: 50px;
  color: $brand-second;
  margin-left: 15px;
  cursor: pointer;
}

.banner_phone-icon {
  .fa-phone-square-alt {
    display: block;
  }

  .fa-phone-alt {
    display: none;
  }
}

.banner_phone-icon:hover,
.banner_phone-icon:active {
  .fa-phone-square-alt {
    display: none;
  }

  .fa-phone-alt {
    display: block;
  }
}

.fa-phone-square-alt:hover {
  color: $brand-main-light;
}

.banner__text {
  font-size: 24px;
  color: $brand-main;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}

.banner__text:first-child {
  margin-right: 5px;
}

.banner__text-wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.banner__sign {
  max-width: 250px;
  margin-top: 15px;
}

.banner__show-number-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.banner__show-number {
  border: 2px solid $brand-second;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

// Style for carousel
.image-gallery-content.fullscreen {
  .image-gallery-image {
    max-height: 95vh;
  }
}

.image-gallery-content {
  .image-gallery-image {
    display: block;
    max-height: 700px;
    img {
      width: unset;
      max-height: inherit;
      display: block;
      margin: 0 auto;
    }
  }
}

.image-gallery-thumbnail-inner {
  img {
    max-height: 60px;
  }
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  color: $brand-main;
  text-shadow: 0 1px 1px #1a1a1a;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: $brand-main-light;
  transform: scale(1.1);
}
// End style Caorusel

@media screen and (min-width: 769px) {
  .offer-description-tablet {
    display: block;
  }

  .offer-description-mobile {
    display: none;
  }

  .offer-form {
    form {
      padding: 50px;
    }
  }
}
