$font-grey: #4d4d4d;
$brand-main: #009499;
$brand-main-light: #0aafb4;
$brand-second: #f7e4a2;

.get-offer {
  background-image: url('/assets/mobile.jpg');
  background-position: center;
  min-height: 500px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;

  h1 {
    font-weight: bold;
  }
}

.get-offer__yellow-bg {
  background-color: rgba(246, 228, 162, 0.9);
  padding: 1.5em 1em;
  max-width: 700px;
}

.get-offer__content {
  flex-direction: column;
  justify-content: center;
  background-position: center;
  color: white;
  padding: 1em;
  display: flex;
}

.get-offer__text {
  margin-top: 20px;
}

.get-offer__font-position {
  margin: 0 auto;
}

.get-offer__button-wrapper {
  display: flex;
  justify-content: center;
}

.get-offer__contact {
  background-color: $brand-main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  justify-content: center;
}

.get-offer__contact-img {
  max-width: 200px;
  margin-bottom: 10px;
}

.get-offer__contact-sign {
  max-width: 300px;
  margin-top: 10px;
  width: 100%;
}

.get-offer__contact-number {
  color: #fff;
}

.get-offer__contact-text {
  text-align: center;
}

@media all and (min-width: 769px) {
  .get-offer {
    background-image: url('/assets/desktop.jpg');
  }

  .get-offer__content {
    flex-direction: column;
    justify-content: flex-end;
    padding: 2em 0;
  }

  .get-offer__yellow-bg {
    padding: 1.5em;
  }

  .get-offer__yellow-bg {
    p {
      text-align: left;
    }
  }

  .get-offer__content {
    flex-direction: row;
  }

  .get-offer__button-wrapper {
    justify-content: left;

    .button--main {
      margin: 20px 0;
    }
  }

  .get-offer__contact {
    flex-direction: row;
    align-items: center;
    padding: 30px 10px;
    justify-content: center;
  }

  .get-offer__contact-text {
    margin-right: 20px;
  }
}

@media all and (min-width: 1408px) {
  .get-offer__yellow-bg {
    padding: 1.5em 4.5em 1.5em 1.5em;
  }
}
