.navbar-wrapper {
  min-height: calc(3.25rem + 2px);
}

.navbar {
  border-bottom: 2px solid #009499;
  position: fixed;
  width: 100%;
}

.navbar-menu {
  justify-content: flex-end;
}

.navbar-burger {
  background-color: transparent;
  border: none;
  height: unset;
}

a.navbar-item:hover {
  color: #009499;
}

@media all and (min-width: 768px) {
  .navbar-wrapper {
    min-height: calc(3.25rem + 8px);
  }

  .navbar-item {
    img {
      max-height: 2.625rem;
    }
  }
}
