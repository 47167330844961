.benefit__title {
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto;
  color: #4d4d4d;
}

.benefit__description {
  color: #4d4d4d;
  font-size: 16px;
  padding: 10px;
}

.benefit__image {
  float: left;
  margin-right: 15px;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.benefit__accent-line {
  border: 1px solid #009499;
  display: flex;
  margin: 5px;
  width: 30%;
}

@media screen and (min-width: 769px) {
  .benefit__description {
    color: #4d4d4d;
    font-size: 20px;
    padding: 10px;
  }
}
