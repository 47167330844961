.cookies {
  position: fixed;
  width: 100%;
  height: 80px;
  background: #242424;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  color: white;
  a {
    color: #f7e4a2;
  }
}

.cookies__button {
  background-color: #009499;
  border-radius: 5px;
  border: none;
  margin-left: 10px;
  height: 35px;
}

.cookies__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
