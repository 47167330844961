$brand-main: #009499;
$brand-main-light: #0aafb4;
$brand-second: #f7e4a2;

body {
  font-family: 'Open Sans', sans-serif;
}

.columns {
  margin-left: 0;
  margin-right: 0;
}

h1 {
  font-size: 26px;
}

h2,
.h2-like {
  font-size: 30px;
  font-weight: bold;
  color: $brand-main;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  text-align: center;
}

.font--grey {
  color: #4d4d4d;
}

.font--white {
  color: #fff;
}

.title-accent:before {
  content: '';
  display: block;
  height: 30px;
  width: 30px;
  background-color: $brand-second;
  left: 8px;
  top: 8px;
  position: relative;
  z-index: -1;
}

p {
  font-size: 24px;
  text-align: justify;
}

.text-accent {
  color: $brand-main;
}

.font-medium {
  font-size: 24px;
}

.font-small {
  font-size: 16px;
}

.font-smallest {
  font-size: 14px;
}

a {
  text-decoration: none;
}

.columns-revers-mobile,
.columns-reverse-desktop {
  display: flex;
  flex-direction: column-reverse;
}

.columns-row-desktop {
  display: flex;
  flex-direction: column;
}

.column-cetner-content {
  display: flex;
  align-items: center;
}

.button {
  background-color: $brand-main;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border: none;
}

.button:focus,
.button.is-focused,
.button:hover,
.button.is-hovered {
  color: white;
}

.button--main {
  width: 250px;
  border-radius: 22px;
  margin: 20px auto;
  height: 55px;
}

.button--dark-background:hover {
  color: #fff;
  background-color: $brand-main-light;
}

.link-center {
  text-align: center;
}

.margin-section {
  margin-top: 7em;
  margin-bottom: 7em;
}

.title-margin {
  margin-bottom: 2em;
}

.input,
.textarea,
.select select {
  border-color: #4d4d4d;
}

@media all and (min-width: 769px) {
  h1 {
    font-size: 48px;
  }

  h2,
  .h2-like {
    font-size: 35px;
    font-weight: bold;
    color: $brand-main;
    text-align: center;
  }

  .columns {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .columns-revers-mobile {
    flex-direction: row;
  }
}

@media all and (min-width: 960px) {
  .is-paddingless-desktop {
    padding: 0 !important;
  }
  .columns-row-desktop {
    flex-direction: row;
  }

  .columns-reverse-desktop {
    flex-direction: row;
  }
}
