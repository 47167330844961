// How it works
.hiw {
  display: flex;
  flex-direction: column;
  background-image: url('/assets/12.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.hiw__title {
  max-width: 45em;
  margin: 0 auto;
  padding-bottom: 3.125em;
  color: white;
}

.hiw-item {
  margin-bottom: 3em;
}

.hiw-item__icon-position {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  git svg {
    margin-right: 25px;
  }
}

.hiw__text-wrapper {
  max-width: 65em;
  margin: 0 auto;
  padding-bottom: 3.125em;
}
.hiw__text {
  padding-left: 5px;
  padding-right: 5px;
  text-align: justify;
}

.hiw-item__title,
.hiw__text {
  color: white;
  padding-bottom: 0.8em;
}

.hiw-item__description {
  text-align: left;
  color: white;
}

.hiw-item__icon {
  width: 135px;
}

@media screen and (min-width: 1024px) {
  .icon-position--left {
    justify-content: flex-end;
  }

  .icon-position--right {
    justify-content: flex-start;
  }
}

@media all and (min-width: 1024px) {
  .hiw-item__title {
    margin-right: -40px;
  }
}
