.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 50px;

  .box {
    max-width: 500px;
    height: 300px;
    width: 100%;
  }
}

.login__unlock {
  text-align: center;
}
.fa-unlock {
  font-size: 50px;
  color: #009499;
}
