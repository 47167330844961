.admin-edit {
  padding-top: 1em;
  padding-bottom: 1em;
}

.toDelete {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
