.privacy-policy {
  margin-top: 100px;

  ul,
  ol {
    margin: 1em 2em 2em 2em;
    line-height: 35px;
  }

  ul {
    list-style-type: initial;
  }

  p {
    font-size: 16px;
    line-height: 35px;
    margin: 1em 0;
  }

  .privacy-policy__header {
    margin: 1em 0;
  }
}
