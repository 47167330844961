.offer-item {
  max-width: 350px;
  padding: 1em;
  border: 1px solid #4d4d4d;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

  img {
    width: calc(100% + 2em);
    margin-left: -1em;
    margin-right: -1em;
    margin-top: -1em;
    max-width: unset;
    height: 200px;
  }
}

.offer-item:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 148, 153, 0.3);
  border: 1px solid #009499;

  img {
    opacity: 0.8;
  }
}

.offer-item__description {
  font-size: 0.9em;
  line-height: 1.75em;
  color: #4d4d4d;
  margin-bottom: -1em;
  margin-right: -15px;
  margin-left: -15px;
  padding: 6px;
}

.offer-item__text-price {
  font-size: 1.25em;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .offer-item {
    max-width: 400px;
    img {
      height: 250px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .offer-item__description {
    font-size: 1em;
    padding: 5px;
    margin-right: -5px;
    margin-left: -5px;
  }
}
