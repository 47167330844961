.faq__question {
  color: #4d4d4d;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  text-align: justify;
  &:hover {
    color: #009499;
  }
}

.faq__question.faq__question--active {
  color: #009499;
  .fa-chevron-down {
    display: none;
  }

  .fa-chevron-up {
    display: block;
  }
}

.faq__question {
  .fa-chevron-down {
    display: block;
  }

  .fa-chevron-up {
    display: none;
  }
}

.fa-chevron-down,
.fa-chevron-up {
  text-align: center;
  margin-top: 20px;
}

.faq__answer {
  font-size: 16px;
  padding: 20px 25px 20px 0;
  padding-top: 20px;
  text-align: justify;
  padding-right: 25px;
  margin: 0 auto;
  color: #4d4d4d;
  display: none;
}

.faq__answer--active {
  display: block;
}

@media screen and (min-width: 769px) {
  .faq__question {
    padding-right: 30px;
  }

  .faq__answer {
    padding-right: 30px;
  }

  .fa-chevron-down,
  .fa-chevron-up {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: unset;
    margin-top: unset;
  }
}
