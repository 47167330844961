.survey {
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey {
  iframe {
    min-height: 5000px;
    height: 100%;
    margin: 5em auto;
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .survey {
    iframe {
      min-height: 4100px;
      height: 100%;
    }
  }
}
