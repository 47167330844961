.footer__content {
  background-image: url(/assets/11.jpg);
  width: 100%;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer__logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__logo {
  max-width: 220px;
}

.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    color: #4d4d4d;
    font-size: 16px;
    padding: 5px 0;
  }
  a:hover {
    color: #009499;
  }
}
