.not-found {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found__text {
  margin-bottom: 50px;
}
