.about-us {
  padding: 5% 5px 0 5px;
  max-width: 1000px;
  margin: 0 auto;
}

.about-us__text-wrapper {
  padding-bottom: 2em;
}

.about-us__text {
  color: #4d4d4d;
  font-size: 24px;
  padding: 1em 0;
  line-height: 1.75;
  text-align: justify;
}

.about-us__button {
  text-align: center;
  margin-bottom: 30px;
}

.about-us__photos {
  margin-bottom: 25px;
}
.about-us__img-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  min-height: 100%;

  p {
    text-align: center;
    color: #4a4a4a;
    font-weight: 600;
  }
}
